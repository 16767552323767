import React, {useEffect, useState} from 'react';
import {Markup} from 'interweave';
import apiFetch from '@wordpress/api-fetch';

const App = () => {
  const [data, setData] = useState();
  const [showNotification, setShowNotification] = useState(false);
  let localStorageNotificationHash = '';

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (!data) {
      return;
    }
    localStorageNotificationHash = loadLocalStorageHash();
    maybeShowNotification();
  }, [data])

  const loadLocalStorageHash = () => {
    let hash = localStorage.getItem('alveno-notification');
    if (hash !== null) {
      return hash;
    }

    return '';
  };

  const closeCallback = (e) => {
    console.log();
    e.preventDefault();
    saveLocalStorageHash();
    setShowNotification(false);
  }

  const saveLocalStorageHash = () => {
    localStorage.setItem('alveno-notification', data.hash);
  }

  const maybeShowNotification = () => {
    if (localStorageNotificationHash !== data.hash) {
      setShowNotification(true);
    }
  };

  const getData = () => {
    apiFetch({
      path: alveno.api_path + '/notification/',
      method: 'GET',
    })
        .then((response) => {
          if (response.data.show) {
            setData(response.data);
          }
        });
  };

  if (!data) {
    return null;
  }

  return (
      <>
        {showNotification &&
            <section className="notification-wrapper">
              <section className="notification-item">
                <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg"
                     className="notification-bell">
                  <path
                      d="M8.321.625c0-.345.325-.625.643-.625.39 0 .679.28.679.625v.656c3.25.314 5.786 2.977 5.786 6.219v1.137c0 1.707.699 3.343 1.94 4.554l.109.106c.333.324.522.73.522 1.223 0 .957-.796 1.73-1.78 1.73H1.78c-.983 0-1.78-.773-1.78-1.73 0-.493.187-.899.521-1.223l.11-.106a6.36 6.36 0 0 0 1.94-4.554V7.5c0-3.242 2.536-5.905 5.786-6.22L8.321.626zM8.964 2.5c-2.805 0-5.143 2.238-5.143 5v1.137c0 2.039-.797 3.996-2.28 5.437l-.11.106c-.093.09-.181.215-.181.34 0 .265.257.48.53.48h14.44a.487.487 0 0 0 .494-.48.479.479 0 0 0-.144-.34l-.109-.106a7.571 7.571 0 0 1-2.318-5.437V7.5c0-2.762-2.302-5-5.18-5zm0 16.25c.594 0 1.073-.348 1.25-.832.116-.328.486-.531.819-.383.334.117.51.473.394.797C11.073 19.305 10.12 20 8.964 20a2.557 2.557 0 0 1-2.39-1.668.625.625 0 0 1 .393-.797c.333-.148.703.055.82.383.176.484.655.832 1.177.832z"
                      fill="#2A343F" fill-rule="nonzero"/>
                </svg>
                <div className="notification-content">
                  <Markup content={data.text} noWrap={true}/>
                </div>
                <button type="button" className="notification-close" onClick={closeCallback}>
                  <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#FFF" fill-rule="nonzero">
                      <path d="M11 1.16 9.898 0 4.674 5.5 9.898 11 11 9.84 6.878 5.5z"/>
                      <path d="M0 1.16 1.102 0l5.224 5.5L1.102 11 0 9.84 4.122 5.5z"/>
                    </g>
                  </svg>
                </button>
              </section>
            </section>
        }
      </>
  );
}
document.addEventListener("DOMContentLoaded", function (event) {
  const items = document.querySelectorAll('.app-notification-bar');
  if (items) {
    items.forEach(item => {
      ReactDOM.render(<App/>, item)
    });
  }
});
